import { CheckAgentService } from "../apiFunction";
import { mapGetters } from "vuex";

export default {
  computed:{
    ...mapGetters("sectionVUEX",{
      uuid: "getUUID"
    }),
  },
  methods: {
    leaveThetest() {
      if(localStorage.getItem("fromCandidate")){
        window.close('','_parent','');
      }
      localStorage.clear();
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },

    nextSelfiePage() {
      this.$router.push({
        path: "/snapshots",
      });
    },
    nextdosanddonts() {
      this.$router.push({
        path: "/dosanddonts",
      });
    },
    goToTypingTest() {
      this.$router.push({
        path: "/typingtest",
      });
    },
    goTocommunicationTest() {
      this.$router.push({
        path: "/communicationtest",
      });
    },
    verifyFunc() {
      this.isLoading = true;
      CheckAgentService(this.testDetails.attemptNo)
        .then((res) => {
          this.isLoading = false;
          if (res.data.data.AGENT_CHECK_RESPONSE) {
            this.verifyAgent = true;
            console.log(this.verifyAgent);
          } else {
            this.$bvModal.show("extensionmodal");
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvModal.show("extensionmodal");
        });
    },
  },
};
