<template>
  <div>
    <template v-if="NavigateWindow">
      <Agentwindows :NavigateWindow="NavigateWindow" />
    </template>

    <template v-if="NavigatemacOS">
      <AgentmacVue :NavigatemacOS="NavigatemacOS" />
    </template>

    <template v-if="NavigateLinux">
      <Agentlinux :NavigateLinux="NavigateLinux" />
    </template>
  </div>
</template>

<script>
import Agentwindows from "../components/Agent/Agentwindows.vue";
import AgentmacVue from "../components/Agent/Agentmac.vue";
import Agentlinux from "../components/Agent/Agentlinux.vue";
export default {
  data() {
    return {
      systemOs: "",
      NavigateWindow: false,
      NavigatemacOS: false,
      NavigateLinux: false,
    };
  },
  components: {
    Agentwindows,
    AgentmacVue,
    Agentlinux,
  },

  created() {
    history.pushState(location.href, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    let Name = "Not known";
    if (navigator.userAgent.indexOf("Win") != -1) {
      Name = "windows";
      this.NavigateWindow = true;
      this.NavigateLinux = false;
      this.NavigatemacOS = false;
    }
    if (navigator.userAgent.indexOf("Mac") != -1) {
      Name = "macOS";
      this.NavigatemacOS = true;
      this.NavigateLinux = false;
      this.NavigateWindow = false;
    }
    if (navigator.userAgent.indexOf("Linux") != -1) {
      Name = "linux";
      this.NavigateLinux = true;
      this.NavigateWindow = false;
      this.NavigatemacOS = false;
    }
    this.systemOs = Name;
  },
};
</script>
